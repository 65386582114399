import React, { Suspense } from 'react';
import 'core-js';
import SettingsContext from '../contexts/settings';
import Splash from './Splash';
import ErrorBoundary from './ErrorBoundary';

const LazyHome = React.lazy(() => import(/* webpackChunkName: "Home" */'./Home'));

const Index = ({ gmapsApiKey, googleAnalyticsId }) => {
  const [settings] = React.useState({ gmapsApiKey, googleAnalyticsId });
  return (
    <ErrorBoundary>
      <SettingsContext.Provider value={settings}>
        <Suspense fallback={<Splash />}>
          <LazyHome />
        </Suspense>
      </SettingsContext.Provider>
    </ErrorBoundary>
  );
};

export default Index;
