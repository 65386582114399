import React, { Component, Suspense } from 'react';
import { withScope, captureException } from '@sentry/browser';

const LazyErrorPage = React.lazy(() => import(/* webpackChunkName: "ErrorPage" */'./ErrorPage'));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      scope.setExtras(errorInfo);
      captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Suspense fallback={null}>
          <LazyErrorPage />
        </Suspense>
      );
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
