import React from 'react';
import { render } from 'react-dom';
import { init as sentryInit } from '@sentry/browser';
import Index from './components/Index';

const container = document.getElementById('app-root');
const gmapsApiKey = container.dataset.googlemapsapikey;
const googleAnalyticsId = container.dataset.googleanalyticsid;
const sentryDSN = container.dataset.sentrydsn;

if (sentryDSN) {
  sentryInit({ dsn: sentryDSN });
}

render(
  <Index
    gmapsApiKey={gmapsApiKey}
    googleAnalyticsId={googleAnalyticsId}
  />,
  container,
);
